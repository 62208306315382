export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
    },

    body: {
      type: String,
    },
  },

  components: {
    hotContentBlock: () => import('~/components/Organisms/ContentBlock/ContentBlock.vue'),
  },

  computed: {
    hasTitle() {
      const hasTitle = this.title

      if (hasTitle) return true
    },

    hasBody() {
      const hasBody = this.body

      if (hasBody) return true
    },
  },
}
