import { render, staticRenderFns } from "./ContentBlocks.vue?vue&type=template&id=13b81500&scoped=true"
import script from "./ContentBlocks.js?vue&type=script&lang=js&external"
export * from "./ContentBlocks.js?vue&type=script&lang=js&external"
import style0 from "./ContentBlocks.scss?vue&type=style&index=0&id=13b81500&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b81500",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default})
